import React from "react"
import SEO from "../components/seo"
import Header from "../components/service/header"
import NavBar from "../components/navbar"
import IndexNavBar from "../components/index-navbar"
import Products from "../components/service/products"
import Layout from "../components/layout"
import ServiceArea from "../components/service/service-area"
import { useTranslation } from "react-i18next"

const ServicePage = () => {
  const { t } = useTranslation()

  const navbarLabels = [
    {
      to: "/service#products",
      label: t("プロダクト"),
    },
    {
      to: "/service#development",
      label: t("プロダクト開発"),
    },
    {
      to: "/service#rd",
      label: t("研究開発"),
    },
    {
      to: "/service#consulting",
      label: t("技術コンサルティング"),
    },
  ]

  return (
    <Layout>
      <SEO title="Service" />
      <NavBar />
      <Header />
      <IndexNavBar labels={navbarLabels} />
      <Products id="products" />
      <ServiceArea />
    </Layout>
  )
}

export default ServicePage
