import React from "react"
// import Image from "../image"
import styled from "styled-components"
import { withTheme } from "@material-ui/core"
import _Container from "../container"
import Koushori from "../../images/service/高処理性能.svg"
import Agile from "../../images/service/アジャイル実装.svg"
import Hannyousei from "../../images/service/汎用性.svg"
import Domain from "../../images/service/ドメイン知識.svg"
import localTheme from "./theme"
import ArrowButton from "../arrowButton"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
// import { ArrowButton } from "./arrowButton"
// import { StyledLink as Link } from "../link"
// import { CardContainer,Card, CardContent, Logo } from "../index/card"

export default ({ ...props }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  return (
    <Container {...props}>
      <div id="development">
        <h1>Development</h1>
        {language === "ja" ? <h2>（プロダクト開発）</h2> : []}
      </div>
      <p>
        {t(
          "We focus on developing and delivering systems for companies that want to implement products and technologies that solve scalability problems in their business. We propose a combination of the latest technologies and existing stable technologies.",
          { ns: "index" }
        )}
      </p>
      <h3>{t("提供価値", { ns: "service" })}</h3>
      <FlexContainer>
        <FlexItem>
          <img src={Koushori} alt="高処理性能" width="100%" />
          <h4>{t("高処理性能", { ns: "service" })}</h4>
        </FlexItem>
        <FlexItem>
          <img src={Agile} alt="アジャイル実装" width="100%" />
          <h4>{t("アジャイル実装", { ns: "service" })}</h4>
        </FlexItem>
      </FlexContainer>
      <ArrowButton
        label={t("お問い合わせ")}
        to="/contact"
        style={{ margin: "53px auto 0px", maxWidth: "100%" }}
      />

      <Hr />
      <div id="rd">
        <h1>{"R&D"}</h1>
        {language === "ja" ? <h2>（研究開発）</h2> : []}
      </div>
      <p>
        {t(
          "Our research area is specialized in decentralized scalability, privacy, and interoperability. Furthermore, to support our research and the community, we are actively developing different types of decentralized protocols.",
          { ns: "index" }
        )}
      </p>
      <h3>{t("提供価値", { ns: "service" })}</h3>

      <FlexContainer>
        <FlexItem>
          <img src={Koushori} alt="処理性能の改善" width="100%" />
          <h4>{t("処理性能の改善", { ns: "service" })}</h4>
        </FlexItem>
        <FlexItem>
          <img src={Agile} alt="秘匿化" width="100%" />
          <h4>{t("秘匿化", { ns: "service" })}</h4>
        </FlexItem>
      </FlexContainer>
      <ArrowButton
        label={t("お問い合わせ")}
        to="/contact"
        style={{ margin: "53px auto 0px", maxWidth: "100%" }}
      />
      <Hr />

      <div id="consulting">
        <h1>Tech Consulting</h1>
        {language === "ja" ? <h2>（技術コンサルティング）</h2> : []}
      </div>
      <p>
        {t(
          "We provide technical consulting services and technical support for enterprises that are implementing Web3.0 technology to their organization.",
          { ns: "index" }
        )}
      </p>
      <h3>{t("提供価値", { ns: "service" })}</h3>
      <FlexContainer>
        <FlexItem>
          <img src={Domain} alt="ブロックチェーンのドメイン知識" width="100%" />
          <h4>{t("Domain Knowledge", { ns: "service" })}</h4>
        </FlexItem>
        <FlexItem>
          <img src={Hannyousei} alt="汎用性" width="100%" />
          <h4>{t("汎用性", { ns: "service" })}</h4>
        </FlexItem>
      </FlexContainer>
      <ArrowButton
        label={t("お問い合わせ")}
        to="/contact"
        style={{ margin: "53px auto 0px", maxWidth: "100%" }}
      />
    </Container>
  )
}

const Container = withTheme(styled(_Container)`
  ${localTheme}
  padding-bottom: 128px;
  background-color: #ffffff;
  letter-spacing: 0px;
  color: #1a1a1a;
  width: 100%;
`)

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 17px;
  margin-right: -5px;
  margin-bottom: -5px;
`

const FlexItem = styled.div`
  margin: 0px 5px 5px;
  width: 150px;
  max-width: 100%;
`

const Hr = withTheme(styled.div`
  margin-top: 83px;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  width: 100vw;
  height: 0px;
  border: 1px solid #cccccc;
  opacity: 1;
  ${props => props.theme.breakpoints.down("xs")} {
    margin-top: 58px;
  }
`)
